<script>
	import { createEventDispatcher, onMount } from 'svelte';
	const dispatch = createEventDispatcher();

	// Props
	export let title = undefined;
	export let zIndex = undefined;
	export let modalClasses = undefined;
	export let bodyStyle = '';
	export let show = false;
	export let closeOnOutsideTap = true;
	export let hideCloseButton = false;
	export let bodyContainerClass = 'px-5 py-2';
	export let bodyMaxHeight = '75vh';

	// Globals
	let mounted = false;

	$: scrollLock(show);

	// Functions

	export const openModal = () => {
		show = true;
		dispatch('modalOpened');
		dispatch('open');
	};

	export const closeModal = () => {
		show = false;
		dispatch('modalClosed');
		dispatch('close'); 
	};

	const scrollLock = (show) => {
		if (mounted) {
			const body = document.querySelector('body');
			body.style.overflow = show ? 'hidden' : 'auto';
		}
	};

	onMount(() => {
		mounted = true;
		scrollLock(show);
	});
</script>

<div
	style="max-width: 100vw; max-height: 100vh; {zIndex ? `z-index: ${zIndex};` : ''}"
	class="bg-gray-200/50 fixed top-0 right-0 left-0 bottom-0 z-40 h-full"
	class:hidden={!show} 
	on:click={() => {
		if (!closeOnOutsideTap) return;
		dispatch('outsideClick'); 
		closeModal();
	}}
>
	<div class="grid content-center w-full h-full">
		<div class="relative px-2 mx-auto w-full {modalClasses ? modalClasses : ''}" on:click|stopPropagation={() => {}} style={bodyStyle}>
			<div class="relative bg-white rounded-xl shadow mx-auto w-full">
				<div class="flex justify-end px-4 py-3 modal-header-border">
					<div class="grid content-center">
						{#if title}
							{@html title}
						{/if}
					</div>
					{#if !hideCloseButton}
						<button
							type="button"
							class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
							on:click={() => { 
								dispatch('closeButtonClick')
								closeModal(); 
							}}
						>
							<svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
								<path
									fill-rule="evenodd"
									d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
									clip-rule="evenodd"
								/>
							</svg>
						</button>
					{/if}
				</div>
				<div class={bodyContainerClass} style="max-height: {bodyMaxHeight};">
					<slot />
				</div>
			</div>
		</div>
	</div>
</div>

<style>
	.modal-body {
		/* min-height: 400px; */
	}
</style>
